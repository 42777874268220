<div
(panup)="onPanUp($event)"
(pandown)="onPanDown($event)">
<!--
(panup)="onPanUp($event)"
(pandown)="onPanDown($event)">
(pan)="onPan($event)"
(panstart)="onPanStart($event)"
(panmove)="onPanMove($event)"
(panend)="onPanEnd($event)"
(pancancel)="onPanCancel($event)"
(panleft)="onPanLeft($event)"
(panright)="onPanRight($event)"-->
  <p>audio-experiments works!</p>
  <h2>
    {{panDirection}}
  </h2>
</div>
