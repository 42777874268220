  <div class="container-fluid" style="overflow-y: scroll; height:60vh;">
  <h1>Me Myself and I</h1>

  <h2>CV</h2>
  <ul>
    <li>
      As of January 2022 KfW: <br />
      Scrum Master in two teams
    </li>
    <li>
      2018 - 2021 Amadeus Germany GmbH: <br />
      Principal Engineer, Product Owner in a SCRUM Team and a KanBan Team, Tribe QA Manager and Chair of Change Control Boards
    </li>
    <li>
      2004 - 2018	Amadeus Germany GmbH: <br />
      Unit Manager Quality Assurance Services
    </li>
    <li>
      1999 - 2004 Amadeus Germany GmbH: <br />
      Test Analyst, mainly focussed on operational tests, performance tests, disruption tests
    </li>
    <li>
      1998 - 1999	Quasimidi GmbH: <br />
      Developer for digital sound processing in synthesizers
    </li>
    <li>
      1997 - 1998	Elmeg GmbH: <br />
      Developer for telecommunication systems
    </li>
    <li>
      1997	Diploma in Mathematics
    </li>
    <li>
      1990 - 1997	Studies of Technomathematik at University of Paderborn with focus on applied maths and electrical engineering.
    </li>
  </ul>
  <h2>Professional and Tool Knowledge</h2>

  <h3>Certificates</h3>
  <div class="indent">
    <table>
      <tr>
        <td>
          2021
        </td>
        <td>
          February: SAFe 5.0 Scrum Master (SM)<br />
          January: SAFe 5.0 Product Owner / Product Manager (PO/PM)
        </td>
      </tr>
      <tr>
        <td>2020</td>
        <td>
          December: SAFe 5.0 Agilist (SA)
        </td>
      </tr>
      <tr>
        <td>2018</td>
        <td>
          Professional SCRUM Master certified by scrum.org<br />
          Professional SCRUM Product Owner certified by scrum.org
        </td>
      </tr>
      <tr>
        <td>2010</td>
        <td>ISTQB Certified Tester</td>
      </tr>
    </table>
  </div>

  <h3>Test Management:</h3>
  <div class="indent">
    HP Quality Center 10<br>
    HP ALM 11.52
  </div>

  <h3>Test Automation:</h3>
  <div class="indent">
    Keyword driven testing with various tools<br>
    Load and performance testing with HP Load Runner
  </div>

  <h3>Expertise in Excel:</h3>
  <div class="indent">
    Formulas, Macros, Pivot Tables, ... you name it
  </div>

  <h2>Hobbies</h2>
  <ul>
    <li>
      I sing in two choirs: "Kammerchor Hofheim" and "Liederkranz Bremthal / Chorisma"
    </li>
    <li>
      I taught myself some web technology. Originally I had started with TYPO3, but found it too flakey and a ship too big to sail for my purposes.
      So I got back to basics, learned some HTML5, some CSS3, some bootstrap, some typescript and then redesigned the page using angular 5.
    </li>
    <li>
      I compose music using Cakewalk by bandlabs. You can find some fragments under the music tab of this site.
    </li>
  </ul>
</div>
