<h1>Home</h1>
<h2>History</h2>
<p>
At first I wanted to learn some web technology from scratch.
To set up a first home page, I chose Typo3, as it was recommended to me from several directions.
</p><p>
Typo3 proved to be more complicated and less mature than I had thought.
Every update would first crash my page and then I would spend nights to get it working again.
Then came the day when I wanted to migrate to fluid templates rather than TypoScript and
that led me into a complete mess.
So at some point I decided, that it wasn't worth it.
</p><p>
Especially as my main goal was, to be able to share my self-composed music.
</p><p>
So now I take a second attempt.
I do not need a full blown content management system.
My simple page is a perfect playground to learn Angular 2.
So here I went and started learning.
</p><p>
For the layout it is still helpfull to know Twitter's bootstrap library.
</p><p>
To create a simple web site, I would not recommend to go for Typo3.
That's really a new definition of taking a sledgehammer to crack a nut.
</p><p>
Update to Angular8 on June 06 2019.<br/>
Update to Angular9 on February 07 2020.<br/>
Update to Angular10 on October 02 2020.<br />
Update to Angular13 on December 03 2021.
</p>
